// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recaps-2018-js": () => import("./../../../src/pages/recaps/2018.js" /* webpackChunkName: "component---src-pages-recaps-2018-js" */),
  "component---src-pages-recaps-2019-js": () => import("./../../../src/pages/recaps/2019.js" /* webpackChunkName: "component---src-pages-recaps-2019-js" */),
  "component---src-pages-recaps-2020-js": () => import("./../../../src/pages/recaps/2020.js" /* webpackChunkName: "component---src-pages-recaps-2020-js" */),
  "component---src-pages-recaps-2021-js": () => import("./../../../src/pages/recaps/2021.js" /* webpackChunkName: "component---src-pages-recaps-2021-js" */),
  "component---src-pages-recaps-2022-js": () => import("./../../../src/pages/recaps/2022.js" /* webpackChunkName: "component---src-pages-recaps-2022-js" */),
  "component---src-pages-recaps-2023-js": () => import("./../../../src/pages/recaps/2023.js" /* webpackChunkName: "component---src-pages-recaps-2023-js" */),
  "component---src-pages-updates-2019-js": () => import("./../../../src/pages/updates/2019.js" /* webpackChunkName: "component---src-pages-updates-2019-js" */),
  "component---src-pages-updates-2020-js": () => import("./../../../src/pages/updates/2020.js" /* webpackChunkName: "component---src-pages-updates-2020-js" */),
  "component---src-pages-updates-2021-js": () => import("./../../../src/pages/updates/2021.js" /* webpackChunkName: "component---src-pages-updates-2021-js" */),
  "component---src-pages-updates-2022-js": () => import("./../../../src/pages/updates/2022.js" /* webpackChunkName: "component---src-pages-updates-2022-js" */),
  "component---src-pages-updates-2023-js": () => import("./../../../src/pages/updates/2023.js" /* webpackChunkName: "component---src-pages-updates-2023-js" */),
  "component---src-pages-updates-2024-js": () => import("./../../../src/pages/updates/2024.js" /* webpackChunkName: "component---src-pages-updates-2024-js" */)
}

